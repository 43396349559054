import { Component, OnInit, HostListener } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  public solution = [];
  public login = [];
  public knowMore: Boolean =true;
  public environment = environment;
  public isHamburgerPopupOpen = false;
  public isProductServiceOpen = false;
  public isLoginContentOpen = false;
  public isSolutionOpen = false;
 
  constructor(private http: HttpClient) {
    this.getSolution();
    this.getLogin();
  }

  ngOnInit() {
    this.knowMore = true;
  }

  getLogin(){
    this.http.get(`${environment.baseUrl}/lgItem/getAll`).subscribe(res => {
      this.login = this.login.concat(res['data']);
    });
  }

  getSolution(){
    this.http.get(`${environment.baseUrl}/solItem/getAll`).subscribe(res => {
      this.solution = this.solution.concat(res['data']);
     
    });
  }

  solutionopener() {
    var click1 = <HTMLElement>document.querySelector("#container");
    var button = <HTMLElement>document.querySelector(".solbtn");
    if (click1.style.display === "none") {
      click1.style.display = "flex";
      button.style.fontWeight = "bold";
    }
  }

  solutionListClose() {
    var click1 = <HTMLElement>document.querySelector("#container");
    var button = <HTMLElement>document.querySelector(".solbtn");
    if (click1.style.display === "flex") {
      click1.style.display = "none";
      button.style.fontWeight = 'normal';
    }
  }

  loginopener() {
    var click = <HTMLElement>document.querySelector(".login-container");
    var btn = <HTMLElement>document.querySelector(".loginBtn");
    var icon = <HTMLElement>document.querySelector("#dp");
    icon.classList.toggle("down");
    if (click.style.display === "none") {
      click.style.display = "block";
      btn.style.fontWeight = 'bold';
    }
    else if(click.style.display === "grid"){
      click.style.display = "block";
      btn.style.fontWeight = 'bold';
    }
    else {
      click.style.display = "none";
      btn.style.fontWeight = 'normal';
    }
  }
  
  caretdown() {
    var caret = document.getElementById("caret-icon");
    caret.classList.toggle("down");
    var accordianicon = document.getElementById("d-icon");
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
  }
  //   if (!(event.target === <HTMLElement>document.querySelector(".loginBtn"))) {
  //     var d = <HTMLElement>document.querySelector(".containerx");
  //     var btn = <HTMLElement>document.querySelector(".loginBtn");
  //     if (d.style.display === "block") {
  //       var icon = <HTMLElement>document.querySelector("#dp");
  //       icon.classList.toggle("down");
  //       d.style.display = "none";
  //       btn.style.fontWeight = 'normal';
  //     }
  //   }
  //   var button = <HTMLElement>document.querySelector(".solbtn");
  //   if (!(event.target === <HTMLElement>document.querySelector(".solbtn"))) {
  //     var dropdown1 = <HTMLElement>document.querySelector("#container");
  //     if (dropdown1.style.display !== "none") {
  //       dropdown1.style.display = "none";
  //       button.style.fontWeight = 'normal';
  //       button.style.borderBottom = "none";
  //     }
  //   }
  //   var popup = <HTMLElement>document.querySelector("#hamburger-accordion");
  //   var box1 = <HTMLElement>document.querySelector("#hambox1");
  //   var box2 = <HTMLElement>document.querySelector("#hambox2");
  //   var icon1 = <HTMLElement>document.querySelector("#d-icon");
  //   var icon2 = <HTMLElement>document.querySelector("#caret-icon");
  //   var hamicon = <HTMLElement>document.querySelector("#ham-icon");
    // if ((event.target === <HTMLElement>document.querySelector("#ham-icon"))) {
  //     if(popup.style.display === "none"){
  //       popup.style.display = "flex";
  //     }  

  //     else if(popup.style.display !== "none"){
  //       popup.style.display = "none"
  //     }  

  //   } else if(event.target !== popup && event.target !== icon1 && event.target !== icon2 &&  ( <HTMLElement>( <HTMLElement>event.target ).parentElement) !== box1 && ( <HTMLElement>( <HTMLElement>event.target ).parentElement) !== box2 && ( <HTMLElement>( <HTMLElement>event.target ).parentElement) !== popup){
  //       popup.style.display = "none";
  //   }
  // }

}
