// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const development = false;
const http = development ? 'http' : 'https';
const ws = development ? 'ws' : 'wss';
export const backendURL = development ? 'localhost:8001' : 'webprod.ginjer.io';


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export interface ENV {
  production: boolean;
  baseUrl: string;
  baseWSS: string;
}

export const environment:ENV = {
  production: false,
  baseUrl: `${http}://${backendURL}`,
  baseWSS: `${ws}://${backendURL}`
};
