import { Component,Input, OnInit } from '@angular/core';
import { mobileAppDesc } from '../componentDS';

@Component({
  selector: 'app-mobile-application',
  templateUrl: './mobile-application.component.html',
  styleUrls: ['./mobile-application.component.css']
})
export class MobileApplicationComponent implements OnInit {

  @Input() cards: Array<mobileAppDesc> = [];
  @Input() gplayImg: Boolean = true;
  @Input() gplayLink: string = '';
  @Input() appStoreLink: string = '';
  @Input() appStoreImg: Boolean = true;

  constructor() { }

  ngOnInit() {
  }

}