import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uFind',
  templateUrl: './uFind.component.html',
  styleUrls: ['./uFind.component.css']
})
export class UFindComponent implements OnInit {

  public features: any;
  public cards: any;
  public lorawanImages: any;
  constructor() { }

  ngOnInit() {
    this.features = [
      {
        name: 'Geolocation',
        imgPath: '../../../assets/uFind/geolocation.png',
        description: "Deployed devices send the thing’s lat/long wirelessly to the cloud.",
        id: '1'
      },
      {
        name: 'Real Time Data Analytics',
        imgPath: '../../../assets/uFind/realTimeDataAnalytics.png',
        description: "Data processing, analytics, and visualization of tracker activity is available in real-time.",
        id: '2'
      },
      {
        name: 'Alerts',
        imgPath: '../../../assets/uFind/alerts.png',
        description: "Automatic alerts are triggered based on geofencing, movement, and more.",
        id: '3'
      },
      {
        name: 'Verification',
        imgPath: '../../../assets/uFind/verification.png',
        description: "Feel good knowing you can verify that your things are safe using our Web or Mobile apps.",
        id: '4'
      },
      {
        name: 'Historical Insights',
        imgPath: '../../../assets/uFind/historicalInsights.png',
        description: "Access to historical data provides deeper learning and insights on movement of your things.",
        id: '5'
      },
      {
        name: 'Live Monitoring',
        imgPath: '../../../assets/uFind/liveMonitoring.png',
        description: "With live data streaming, locating things could not be any easier.",
        id: '6'
      },
    ]

    this.cards = [
      {
        name: 'Tracking Insights', 
        src: '../../../assets/uFind/trackingInsights.png',
        desp: "Trackers are seen moving on the map for quick visibility. Details on the tracker can be seen by simply clicking on the icon."
      },

      {
        name: 'Real-Time Alerts', 
        src: '../../../assets/uFind/realTimeAlerts.png',
        desp: "Your phone will receive notifications in real-time as the trackers are in movement and meet configured alert thresholds. Configure notifications to your liking and remain informed of the location of your things."
      },

      {
        name: 'Assited Navigation', 
        src: '../../../assets/uFind/assistedNavigation.png',
        desp: "You can leverage google maps full navigation capabilities to help you find the things of interest."
      },

      {
        name: 'Android & iOS Support', 
        src: '../../../assets/uFind/androidAndIOSSupport.png',
        desp: "Our Mobile Application Supports Android Operating Systems. iOS Support Will Be Coming Soon."
      },

      {
        name: 'User Friendly Design', 
        src: '../../../assets/uFind/userFriendlyDesign.png',
        desp: "Our Customer's Experience Is Important To us. Our Mobile App Was Designed For Ease Of use And Simplistic Functionality."
      }
    ]

    this.lorawanImages = [
      {
        src: "../../../assets/uFind/uFind Device.png",
        para: "DEVICE"
      },
      {
        src: "../../../assets/uFind/IoTPlatform.png",
        para: "IoT PLATFORM"
      },
      {
        src: "../../../assets/uFind/uFind App.png",
        para: "MOBILE APP"
      }
    ]

  }

}