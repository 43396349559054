import { Component, Input, OnInit } from '@angular/core';
import { Feature } from '../componentDS';

@Component({
  selector: 'app-key-features',
  templateUrl: './key-features.component.html',
  styleUrls: ['./key-features.component.css']
})
export class KeyFeaturesComponent implements OnInit {

  @Input() featureList: Array<Feature> = [];
  @Input() bottomMargin: Boolean = false;
  @Input() keyFeatureContent: String = '';

  constructor() { }

  ngOnInit() {
  }

}
