import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.css'],
})

export class CareersComponent implements OnInit {
    public careerID: string;
    public roles: any = [];
    public environment = environment;
    constructor(private http: HttpClient) {
          this.http.get(`${environment.baseUrl}/roles/getall`).subscribe(res => this.roles = res['data']);
           }
    ngOnInit() {
    }
}
