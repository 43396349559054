import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { PLATFORM_ID, APP_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

import { AppComponent } from "./app.component";
import { AboutusComponent } from "./layout/aboutus/aboutus.component";
import { CareersComponent } from "./layout/careers/careers.component";
import { ContactusComponent } from "./layout/contactus/contactus.component";
import { EventsComponent } from "./layout/events/events.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";
import { HomepageComponent } from "./layout/homepage/homepage.component";
import { NewsComponent } from "./layout/news/news.component";
import { BlogsComponent } from "./layout/blogs/blogs.component";
import { BlogDetailsComponent } from "./layout/blogs/blog-details/blog-details.component";
import { DigitizeComponent } from "./layout/homepage/digitize/digitize.component";
import { IntroComponent } from "./layout/homepage/intro/intro.component";
import { ServicesComponent } from "./layout/homepage/services/services.component";
import { StayingComponent } from "./layout/homepage/staying/staying.component";
import { ConnectingDevicesComponent } from "./layout/homepage/connecting-devices/connecting-devices.component";
import { ConnectingDevice2Component } from "./layout/homepage/connecting-device2/connecting-device2.component";
import { UBeamComponent } from "./layout/uBeam/uBeam.component";
import { UFindComponent } from "./layout/uFind/uFind.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule, MatSnackBarModule } from "@angular/material";
import { MatIconModule } from "@angular/material/icon";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { CareerFormComponent } from "./layout/careers/career-form/career-form.component";
import { Ng2TelInputModule } from "ng2-tel-input";
import { HttpClientModule } from "@angular/common/http";
import { UParkComponent } from "./layout/upark/upark.component";
import { GetInTouchComponent } from "./layout/components/get-in-touch/get-in-touch.component";
import { UfreezeComponent } from "./layout/ufreeze/ufreeze.component";
import { EmergencyAlertSystemComponent } from "./layout/emergencyAlertSystem/emergencyAlertSystem.component";
import { CleanBinComponent } from "./layout/cleanbin/cleanbin.component";
import { KeyFeaturesComponent } from "./layout/components/key-features/key-features.component";
import { ProximityTracingComponent } from "./layout/proximity-tracing/proximity-tracing.component";
import { EndToEndComponent } from "./layout/components/end-to-end/end-to-end.component";
import { MobileApplicationComponent } from "./layout/components/mobile-application/mobile-application.component";
import { SmartIrrigationComponent } from "./layout/smart-irrigation/smart-irrigation.component";
import { GinjerComponent } from "./layout/ginjer/ginjer.component";
import { NetworkCoverageComponent } from "./layout/network-coverage/network-coverage.component";
import { ManagedNetworkServicePageComponent } from "./layout/managed-network-service-page/managed-network-service-page.component";
import { USenseComponent } from './layout/u-sense/u-sense.component';
import { UploadImageComponent } from "./layout/upload-image/upload-image.component";
import { CaseStudiesComponent } from "./layout/case-studies/case-studies.component";
import { NetsyComponent } from "./layout/netsy/netsy.component";
import { IotAnalyticsPageComponent } from "./iot-analytics-page/iot-analytics-page.component";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";
import { TermsPageComponent } from "./terms-page/terms-page.component";

@NgModule({
  declarations: [
    AppComponent,
    AboutusComponent,
    CareersComponent,
    ContactusComponent,
    EventsComponent,
    FooterComponent,
    HeaderComponent,
    HomepageComponent,
    NewsComponent,
    BlogsComponent,
    BlogDetailsComponent,
    DigitizeComponent,
    IntroComponent,
    ServicesComponent,
    StayingComponent,
    ConnectingDevicesComponent,
    ConnectingDevice2Component,
    CareerFormComponent,
    UParkComponent,
    UBeamComponent,
    UfreezeComponent,
    GetInTouchComponent,
    UFindComponent,
    EmergencyAlertSystemComponent,
    CleanBinComponent,
    KeyFeaturesComponent,
    ProximityTracingComponent,
    EndToEndComponent,
    MobileApplicationComponent,
    SmartIrrigationComponent,
    GinjerComponent,
    NetworkCoverageComponent,
    ManagedNetworkServicePageComponent,
    USenseComponent,
    UploadImageComponent,
    CaseStudiesComponent,
    NetsyComponent,
    IotAnalyticsPageComponent,
    TermsConditionsComponent,
    TermsPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "senra-website" }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MaterialFileInputModule,
    MatSnackBarModule,
    Ng2TelInputModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? "in the browser"
      : "on the server";
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
