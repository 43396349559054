import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-iot-analytics-page',
  templateUrl: './iot-analytics-page.component.html',
  styleUrls: ['./iot-analytics-page.component.css']
})
export class IotAnalyticsPageComponent implements OnInit {

  constructor( private appComponent: AppComponent) {
    this.appComponent.isHeaderFooterVisible = false;
  }

  ngOnInit() {
  }

}