import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staying',
  templateUrl: './staying.component.html',
  styleUrls: ['./staying.component.css']
})
export class StayingComponent implements OnInit  {
  public isVideoOpen = false;

  constructor() { 
  }

  playVideo() {
    this.isVideoOpen = true;
    let body = document.getElementById("body");
    body.style.opacity=".2";
  }

  closeVideo() {
    this.isVideoOpen = false;
    let body = document.getElementById("body");
    body.style.opacity="1";
  }

  ngOnInit() {
  }
}