import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-u-sense',
  templateUrl: './u-sense.component.html',
  styleUrls: ['./u-sense.component.css']
})
export class USenseComponent implements OnInit {
  lorawanImages: { src: string; para: string; }[];

  constructor() { }

  ngOnInit() {
    
    this.lorawanImages = [
      {
        src: "../../../assets/uSense/gateway.png",
        para: "GATEWAY"
      },
      {
        src: "../../../assets/uSense/sensors.png",
        para: "SENSORS"
      },
      {
        src: "../../../assets/uSense/data streaming.png",
        para: "DATA STREAMING"
      },
      {
        src: "../../../assets/uSense/data analytics.png",
        para: "DATA ANALYTICS"
      }
    ]
  }

}
