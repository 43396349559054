import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  public newEvents = [];
  public pastEvents = [];
  public flagNew : Boolean = true;
  public flagPast : Boolean = true;
  public environment = environment;
  public month = ["","Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  constructor(private http: HttpClient) {
     this.loadPastEvent();
     this.loadNewEvent();
     
  }

  ngOnInit() {
  }


  loadNewEvent() {
    this.http.get(`${environment.baseUrl}/events/loadmore/${this.newEvents.length}/6?flag=new`).subscribe(res => {
        this.newEvents = this.newEvents.concat(res['data']);
        if(this.newEvents.length==parseInt(res['info'])){
           this.flagNew=false;
         }
        });
  }
  loadPastEvent() {
    this.http.get(`${environment.baseUrl}/events/loadmore/${this.pastEvents.length}/3?flag=past`).subscribe(res => {
        this.pastEvents = this.pastEvents.concat(res['data']);
        if(this.pastEvents.length==parseInt(res['info'])) {
           this.flagPast=false;
         }
    
  })}
}
