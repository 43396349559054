import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsComponent } from './layout/events/events.component';
import { AboutusComponent } from './layout/aboutus/aboutus.component';
import { CareersComponent } from './layout/careers/careers.component';
import { ContactusComponent } from './layout/contactus/contactus.component';
import { HomepageComponent } from './layout/homepage/homepage.component';
import { BlogsComponent } from './layout/blogs/blogs.component';
import { BlogDetailsComponent } from './layout/blogs/blog-details/blog-details.component';
import { NewsComponent } from './layout/news/news.component';
import { UParkComponent } from './layout/upark/upark.component';
import { UfreezeComponent } from './layout/ufreeze/ufreeze.component';
import { UBeamComponent } from './layout/uBeam/uBeam.component';
import { UFindComponent } from './layout/uFind/uFind.component';
import { EmergencyAlertSystemComponent } from './layout/emergencyAlertSystem/emergencyAlertSystem.component';
import { CleanBinComponent } from './layout/cleanbin/cleanbin.component';
import { ProximityTracingComponent } from './layout/proximity-tracing/proximity-tracing.component';
import { SmartIrrigationComponent } from './layout/smart-irrigation/smart-irrigation.component';
import { GinjerComponent } from './layout/ginjer/ginjer.component';
import { NetworkCoverageComponent } from './layout/network-coverage/network-coverage.component';
import { ManagedNetworkServicePageComponent } from './layout/managed-network-service-page/managed-network-service-page.component';
import { USenseComponent } from './layout/u-sense/u-sense.component';
import { UploadImageComponent } from './layout/upload-image/upload-image.component';
import { CaseStudiesComponent } from './layout/case-studies/case-studies.component';
import { NetsyComponent } from './layout/netsy/netsy.component';
import { IotAnalyticsPageComponent } from './iot-analytics-page/iot-analytics-page.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TermsPageComponent } from './terms-page/terms-page.component';

const routes: Routes = [
  {
    path: 'eula',
    component: IotAnalyticsPageComponent
  },
  {
    path: 'events',
    component: EventsComponent
  },
  {
    path: 'news' , 
    component: NewsComponent
  },
  {
    path: 'CleanBin' , 
    component: CleanBinComponent
  },
  {
    path: 'aboutUs',
    component: AboutusComponent
  },
  {
    path: 'careers',
    component: CareersComponent
  },
  {
    path: 'contactUs',
    component: ContactusComponent
  },
  {
    path: 'uFreeze',
    component: UfreezeComponent
  },
  {
    path: 'blogs',
    component: BlogsComponent,
  },
  {
    path: 'blogs/:_id',
    component: BlogDetailsComponent
  },
  {
    path: 'caseStudies',
    component: CaseStudiesComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'uBeam',
    component: UBeamComponent
  },
  {
    path: 'uPark',
    component: UParkComponent
  },
  {
    path: 'uFind',
    component: UFindComponent
  },
  {
    path: 'upload-image',
    component: UploadImageComponent
  },
  {
    path: 'emergencyAlertSystem',
    component: EmergencyAlertSystemComponent
  },
  {
    path: 'Tracing',
    component: ProximityTracingComponent
  },
  {
    path: 'SmartIrrigation',
    component: SmartIrrigationComponent
  },
  {
    path: 'Ginjer',
    component: GinjerComponent
  },
  {
    path: 'services',
    component: ManagedNetworkServicePageComponent
  },
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'networkCoverage',
    component: NetworkCoverageComponent
  },
  {
    path: 'uSense',
    component: USenseComponent
  },
  {
    path: 'Netsy',
    component: NetsyComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'terms&conditions',
    component: TermsPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // Add options right here
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export const routingComponent = [
  EventsComponent,
  AboutusComponent,
  CareersComponent,
  ContactusComponent,
  HomepageComponent,
  BlogsComponent,
  BlogDetailsComponent,
  NewsComponent,
  UParkComponent,
  UBeamComponent,
  UFindComponent,
  UfreezeComponent,
  ProximityTracingComponent,
  EmergencyAlertSystemComponent,
  CleanBinComponent,
  SmartIrrigationComponent,
  GinjerComponent,
  NetworkCoverageComponent,
  ManagedNetworkServicePageComponent,
  USenseComponent,
  NetsyComponent,
  IotAnalyticsPageComponent,
  TermsConditionsComponent,
  TermsPageComponent
]
