import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  public featuredNews: any = [];
  public flagfeature : Boolean = true;
  public pressNews: any = [];
  public flagPress : Boolean = true;
  public environment = environment;
  public month = ["","Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  constructor(private http: HttpClient) 
  {
    this.loadFeature();
    this.loadPress();
  }

  ngOnInit() {
  }

  loadFeature() {
    this.http.get(`${environment.baseUrl}/news/load/${this.featuredNews.length}/6?flag=featured`).subscribe(res => {
      this.featuredNews = this.featuredNews.concat(res['data']);
      if(this.featuredNews.length==parseInt(res['info'])) {
        this.flagfeature=false;
      }
      this.featuredNews.forEach(featureNews => {
        featureNews.category = 'Featured News';
      });
    });
  }
  
  loadPress() {
    this.http.get(`${environment.baseUrl}/news/load/${this.pressNews.length}/6?flag=press`).subscribe(res => {
      this.pressNews = this.pressNews.concat(res['data']);
      if(this.pressNews.length==parseInt(res['info'])) {
        this.flagPress=false;
      }
      this.pressNews.forEach(pressNews => {
        pressNews.category = 'Press Releases';
      });
    })
  }
}
