import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-irrigation',
  templateUrl: './smart-irrigation.component.html',
  styleUrls: ['./smart-irrigation.component.css']
})
export class SmartIrrigationComponent implements OnInit {
  public features : any;

  constructor( ) { }

  ngOnInit() {
    this.features = [
      {
        name: 'Soil Monitoring',
        imgPath: '../../../assets/Smart Irrigation/monitors soil moisture.png',
        description: "Solution Monitors Soil Moisture & Temperature",
        id: '1'
      },
      {
        name: 'Environmental Monitoring',
        imgPath: '../../../assets/Smart Irrigation/Environmental Monitoring.png',
        description: "Solution Monitors Environmental Temperature And Humidity",
        id: '2'
      },
      {
        name: 'Controls Irrigation',
        imgPath: '../../../assets/Smart Irrigation/controls irrigation.png',
        description: "Automated And Scheduled Irrigation Valve Control (On/Off) Based On Soil Moisture Level",
        id: '3'
      },
      {
        name: 'Analytics & Reporting',
        imgPath: '../../../assets/Smart Irrigation/ANALYTICS & REPORTING.png',
        description: "Generate Environmental / Ambient Temperature & Humidity Reports Upon Request",
        id:'4'
      },
      {
        name:'Historical Insights',
        imgPath:'../../../assets/Smart Irrigation/HISTORICAL INSIGHTS.png',
        description: "Access To Historical Data Provides Deeper Learning And Insights On Irrigation Activity And Safety",
        id: '5'
      },
      {
        name: 'Live Monitoring',
        imgPath: '../../../assets/Smart Irrigation/LIVE MONITORING.png',
        description: "Mobile App To Recive Emergency Alerts From Anywhere",
        id: '6'
      },
    ]

    
  }

}
