import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public solution = [];
  public environment = environment;

  constructor(private httpmodule:HttpClient) {
    this.getSolution();
    
   }

  ngOnInit() {

  }
  
  getSolution(){
    this.httpmodule.get(`${environment.baseUrl}/solItem/getAll`).subscribe(res => {
      this.solution = this.solution.concat(res['data']);
    });
  }

}
