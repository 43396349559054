import {Component, OnInit } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { ActivatedRoute } from '@angular/router';
import showdown from 'showdown';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-network-coverage',
  templateUrl: './network-coverage.component.html',
  styleUrls: ['./network-coverage.component.css']
})

  export class NetworkCoverageComponent implements OnInit{
 
  title ='google-maps';
  public map: google.maps.Map;
  public markers: any;
  public geocoder: google.maps.Geocoder;
  public infoWindow: google.maps.InfoWindow;

  constructor() { 
  }

  ngOnInit(): void {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 28.55830922, lng: 77.208168577 },
      zoom: 4
    })
    fetch('https://us-central1-senra-f2518.cloudfunctions.net/coverage').then(resp => resp.json()).then(data => {
      if (data) {
        this.initializeMarker(data.marker);
        data.region.forEach(region => {
          new google.maps.Polygon({
            paths: region,
            strokeColor: '#5cb85c',
            strokeOpacity: 0,
            strokeWeight: 1,
            fillColor: '#5cb85c',
            fillOpacity: 0.7,
            map: this.map,
          });
        });
      }
    });
    this.geocoder = new google.maps.Geocoder();
    this.infoWindow = new google.maps.InfoWindow;
  }

  showMessage(message) {
    let errorMessage = (<HTMLInputElement>document.getElementById("app-message"));
    errorMessage.innerHTML = message.label;
    errorMessage.style.padding = '5px';
    message.err ? errorMessage.classList.add('error') : errorMessage.classList.add('info');

    setTimeout(() => {
    errorMessage.innerHTML = '';
    errorMessage.style.padding = '0';
    message.err ? errorMessage.classList.remove('error') : errorMessage.classList.remove('info');
    }, 3000);
  }

  searchZipcode() {
    let inputpincode = parseFloat((<HTMLInputElement>document.getElementById("pincode")).value);
    if (inputpincode.toString().length != 6) {
      this.showMessage({label: 'Invalid Pincode', err: true});
      return;
    }
    let geocoder = new google.maps.Geocoder();
    let address = inputpincode;
    geocoder.geocode({ 'address':'inputpincode '+ address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let latitude = results[0].geometry.location.lat();
        let longitude =results[0].geometry.location.lng();
        this.infoWindow.setPosition({lat: latitude, lng: longitude});
        this.infoWindow.setContent(results[0].formatted_address);
        this.infoWindow.open(this.map);
        this.map.setCenter({lat: latitude, lng: longitude});
        this.map.setZoom(10);
      } else {
        this.showMessage({label: 'Invalid Location', err: true});
      }
    });
  }

  initializeMarker(markerData) {
    this.markers = [];
    markerData.forEach(marker => {
      this.markers.push(new google.maps.Marker({
        position: {lat: marker.lat, lng: marker.lng},
        title: `${marker.city}`,
        map: this.map
      }));
    });
  }
 
}


 


  