import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upark',
  templateUrl: './upark.component.html',
  styleUrls: ['./upark.component.css']
})
export class UParkComponent implements OnInit {

  public features:any;
  public cards:any;
  public lorawanImages:any;

  constructor() { }

  ngOnInit() {

    this.features=[
      { 
        name:'Vehical Detection',
        imgPath:'../../../assets/uPark/VEHICLE DETECTION.png',
        description:"Deployed sensors detect vehicle presence and send alerts wirelessly to the cloud.",
        id:'1'
      },
      { 
        name:'Real Time Data Analytics',  
        imgPath:'../../../assets/uPark/REAL-TIME DATA ANALYTICS.png',
        description:"Data processing, analytics, and visualization of parking activity is available in real-time.",
        id:'2'
      },
      { 
        name:'Parking Enforcement', 
        imgPath:'../../../assets/uPark/PARKING ENFORCEMENT.png',
        description:"Parking enforcement personnel recieve automatic alerts of unauthorized parking.",
        id:'3'
      },
      { 
        name:'Parking Status' ,  
        imgPath:'../../../assets/uPark/PARKING STATUS.png',
        description:"Immediate access to parking spots availability and activity is provided via Web or Mobile app.",
        id:'4'
      },
      { 
        name:'Historical Insights', 
        imgPath:'../../../assets/uPark/HISTORICAL INSIGHTS.png',
        description:"Access to historical parking data provides deeper learning and insights on parking activity.",
        id:'5'
      },
      { 
        name: 'Live Monitoring', 
        imgPath:'../../../assets/uPark/LIVE MONITORING.png',
        description:"With live data streaming, parking management is easier and more efficient.",
        id:'6'
      },
    ]

    this.cards=[
      { 
        name:'Parking Spot Insights',
        src:'../../../assets/uPark/PARKING SPOT INSIGHTS.png',
        desp:"Parking spots can be seen on the map and change colors based on the occupancy. Details on the parking spot can be seen by simply clicking on the spot."
      },

      { 
        name:'Real Time Alerts',
        src:'../../../assets/uPark/REAL-TIME ALERTS.png',
        desp:"Your phone will recieve notifications in real-time as vehicles park on unauthorized locations. Configure notifications to your liking and remain informed of parking activity."
      },

      { 
        name:'Assisted Navigation',
        src:'../../../assets/uPark/ASSISTED NAVIGATION.png',
        desp:"You can leverage google maps full navigation capabilities to help you find the selected parking spot."
      },

      { 
        name:'Android & iOS Support' ,
        src:'../../../assets/uPark/ANDROID & iOS Support.png',
        desp:"Our Mobile Application Supports Android Operating Systems. iOS Support Will Be Coming Soon."
      },

      { 
        name:'User Friendly',
        src:'../../../assets/uPark/USER FRIENDLY DESIGN.png',
        desp:"Our Customer's Experience Is Important To us. Our Mobile App Was Designed For Ease Of use And Simplistic Functionality."
      }
    ]

    this.lorawanImages =[
      {
        src:"../../../assets/uPark/uPark Device.png",
        para:"DEVICE"
      },
      {
        src:"../../../assets/uPark/uPark IoT Platform.png",
        para:"IoT PLATFORM"
      },
      {
        src:"../../../assets/uPark/uPark App.png",
        para:"MOBILE APP"
      }
    ]

  }

}