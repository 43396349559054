import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emergencyAlertSystem',
  templateUrl: './emergencyAlertSystem.component.html',
  styleUrls: ['./emergencyAlertSystem.component.css']
})
export class EmergencyAlertSystemComponent implements OnInit {
  public features: any;
  
  constructor( ) { }

  ngOnInit() {
    this.features = [
      {
        name: 'Panic Button',
        imgPath: '../../../assets/Emergency Alert System/panicButton.png',
        description: "Employees can trigger emergency alarms by a push of a button.",
        id: '1'
      },
      {
        name: 'Analytics & Reporting',
        imgPath: '../../../assets/Emergency Alert System/realTimeDataAnalytics.png',
        description: "Generate incident and safety reports upon request.",
        id: '2'
      },
      {
        name: 'Sensor Maps',
        imgPath: '../../../assets/Emergency Alert System/sensorMaps.png',
        description: "Leverage floor plans or facility maps to obtain emergency location.",
        id: '3'
      },
      {
        name: 'Quick & Easy Setup',
        imgPath: '../../../assets/Emergency Alert System/quickAndEasySetup.png',
        description: "Easily install sensors and gateway and be up and running in minutes.",
        id: '4'
      },
      {
        name: 'Historical Insights',
        imgPath: '../../../assets/Emergency Alert System/historicalInsights.png',
        description: "Access to historical data provides deeper learning and insights on employee activity and safety.",
        id: '5'
      },
      {
        name: 'Live Monitoring',
        imgPath: '../../../assets/Emergency Alert System/liveMonitoring.png',
        description: "Mobile app to receive emergency alerts from anywhere.",
        id: '6'
      },
    ]

    
  }

}
