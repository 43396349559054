import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import showdown from 'showdown';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.css']
})

export class BlogDetailsComponent implements OnInit {
    public blogd: any;
    public limitedItems: any[] = [];
    public showdown: any;
    public month = ["", "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    public queryParams: any;
    public environment = environment;
    constructor(private route: ActivatedRoute, private http: HttpClient) {
        this.showdown = new showdown.Converter();
        this.route.queryParams.subscribe(params => {
            this.queryParams = params.id;
            this.loadOne();
            this.loadAll();
        });
    }

    ngOnInit() {
    }

    shuffleArray(array: any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    loadOne() {
        this.http.get(`${environment.baseUrl}/${this.route.snapshot.url.join('/')}`, { params: { id: this.queryParams } }).subscribe(res => {
            this.blogd = res['data'];
            let para = this.blogd['content'].split("\n");
            let filteredAsIs = para.filter((str) => str != "").map((str) => this.showdown.makeHtml(str));
            this.blogd["filtered"] = filteredAsIs;
        });
    }

    loadAll() {
        this.http.get(`${environment.baseUrl}/blogs/getall`).subscribe(res => {
            this.limitedItems = res['data'];
            this.limitedItems = this.shuffleArray(this.limitedItems).filter((arr) => arr.heading != this.route.snapshot.url[1].path.toString());
        });
    }
}
