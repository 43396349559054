import { ImplicitReceiver } from "@angular/compiler";
import { Component,OnInit } from "@angular/core";
 @Component({
     selector: 'app-ginjer',
     templateUrl: './ginjer.component.html',
     styleUrls: ['./ginjer.component.css']
 })

 export class GinjerComponent implements OnInit {
     public Cards: any;
     public cards: any;

    constructor( ) { }

    ngOnInit() {
        this.Cards = [
            {
                name: 'LOW-COST SOLUTION',
                src: '../../../assets/Ginjer 2.0/LOW-COST SOLUTION.png',
                desp: 'Our Pricing Starts At 35 INR Per Month/Device.'
            },

            {
                name: 'REAL-TIME DATA ANALYTICS',
                src: '../../../assets/Ginjer 2.0/REAL-TIME DATA ANALYTICS.png',
                desp: 'Sensing In Real-Time With Powerful Reporting Tools.'
            },

            {
                name: 'DEVICE MANAGEMENT',
                src: '../../../assets/Ginjer 2.0/DEVICE MANAGEMENT.png',
                desp: 'Monitor The Health And Status Of Your Devices.'
            },

            {
                name: 'ROI CALCULATION',
                src: '../../../assets/Ginjer 2.0/ROI CALCULATIONS.png',
                desp: 'Determine Your Business Spendings And Cost Savings.'
            }
        ]

        this.cards = [
            {
                name: 'REAL-TIME DATA ANALYTICS',
                src: '../../../assets/Ginjer 2.0/REAL-TIME DATA ANALYTICS.png',
                desp: 'Analyze your sensor data in real-time to make informed decisions while on the go.'
            },

            {
                name: 'SUPPORT MULTIPLE SOLUTIONS',
                src: '../../../assets/Ginjer 2.0/Support Multiple SOLUTIONs.png',
                desp: 'Get insights of all your deployed solutions directly from your phone.'
            },

            {
                name: 'REAL-TIME ALERTS',
                src: '../../../assets/Ginjer 2.0/REAL-TIME ALERTS.png',
                desp: 'Your phone will receive notifications in real-time once data has exceeded your desired thresholds. Configure notifications to your liking and remain informed at all times .'
            },

            {
                name: 'USER FRIENDLY DESIGN',
                src: '../../../assets/Ginjer 2.0/USER FRIENDLY DESIGN.png',
                desp: 'Our customer’s experience is important to us.  Our mobile app was designed for ease of use and simplistic functionality.'
            }
        ]
    }
}