import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleanbin',
  templateUrl: './cleanbin.component.html',
  styleUrls: ['./cleanbin.component.css']
})
export class CleanBinComponent implements OnInit {
  public features: any;
  public cards: any;
  public lorawanImages: any;
  constructor() { }

  ngOnInit() {
    this.features = [
      {
        name: 'Waste Detection',
        imgPath: '../../../assets/image/WASTE DETECTION.png',
        description: "Deployed sensors detect objects placed in the waste bin and send alerts to the cloud.",
        id: '1'
      },
      {
        name: 'Real-Time Data Analytics',
        imgPath: '../../../assets/image/REAL-TIME DATA ANALYTICS.png',
        description: "Data processing, analytics, and visualization of waste bin activity is available in real-time.",
        id: '2'
      },
      {
        name: 'Waste Level Updates',
        imgPath: '../../../assets/image/WASTE LEVEL UPDATES.png',
        description: "Get automatic updates on the waste buildup and fill levels of each bin.",
        id: '3'
      },
      {
        name: 'Route Planning',
        imgPath: '../../../assets/image/ROUTE PLANNING.png',
        description: "Plan better waste pick-up schedules with automated driving routes suggestions.",
        id: '4'
      },
      {
        name: 'Historical Insights',
        imgPath: '../../../assets/image/HISTORICAL INSIGHTS.png',
        description: "Access to historical waste bin data provides deeper learning and insights on waste collection.",
        id: '5'
      },
      {
        name: 'Live Monitoring',
        imgPath: '../../../assets/image/LIVE MONITORING.png',
        description: "With live data streaming, waste management is easier and more efficient.",
        id: '6'
      },
    ]

    this.cards = [
      {
        name: 'Waste Collection Insights',
        src: '../../../assets/image/WASTE COLLECTION INSIGHTS.png',
        desp: "Waste bins can be seen on the map and change colors based on the fill level. Details on the waste bin can be seen by simply clicking on the bin."
      },

      {
        name: 'Real-Time Alerts',
        src: '../../../assets/image/REAL-TIME ALERTS.png',
        desp: "Your Phone Will Receive Notifications In Real-Time Once Waste Bins Are Full. Configure Notifications To Your Liking And Remain Informed Of Trash Buildup."
      },

      {
        name: 'Route Optimization',
        src: '../../../assets/image/ROUTE OPTIMIZATION.png',
        desp: "Waste collection routes are automatically updated based on bin fill levels to reduce travel time and save money on fuel."
      },

      {
        name: 'Android & iOS Support',
        src: '../../../assets/image/ANDROID & iOS Support.png',
        desp: "Our Mobile Application Supports Android Operating Systems. iOS Support Will Be Coming Soon."
      },

      {
        name: 'User Friendly Design',
        src: '../../../assets/uFind/userFriendlyDesign.png',
        desp: "Our Customer's Experience Is Important To us. Our Mobile App Was Designed For Ease Of use And Simplistic Functionality."
      }
    ]
    this.lorawanImages = [
      {
        src: "../../../assets/image/Group 4452.png",
        para: "Device"
      },
      {
        src: "../../../assets/uFreeze/Iot Platform.png",
        para: "IoT Platform"
      },
      {
         src:"../../../assets/image/CleanBin App.png",
        para: "Mobile App"
      }
    ]
  }

}