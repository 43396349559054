import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proximity-tracing',
  templateUrl: './proximity-tracing.component.html',
  styleUrls: ['./proximity-tracing.component.css']
})
export class ProximityTracingComponent implements OnInit {

  public features: any;
  constructor( ) { }
 
  ngOnInit() {
    this.features = [
      {
        name: 'Proximity Detection',
        imgPath: '../../../assets/Proximity & Contact Tracing/PROXIMITY.png',
        description: "Devices Within 1.5 Meters Of Distance Send Alerts Wirelessly To The Cloud. "
      },
      {
        name: 'Analytics & Reporting',
        imgPath: '../../../assets/Proximity & Contact Tracing/REAL-TIME DATA ANALYTICS.png',
        description: "Centralized Data Processing Of Contact Events And Generation Of Safety Reports."
      },
      {
        name: 'Area Based Enforcement',
        imgPath: '../../../assets/Proximity & Contact Tracing/AREA BASED.png',
        description: "Regulate The Density Of Personnel And Receive."
      },
      {
        name: 'Policy Compliance',
        imgPath: '../../../assets/Proximity & Contact Tracing/POLICY.png',
        description: "Ensure Safety Policies Are Being Met And Are Enforced."
      },
      {
        name: 'Historical Insights',
        imgPath: '../../../assets/Proximity & Contact Tracing/HISTORICAL INSIGHTS.png',
        description: "Access To Historical Data Provides Deeper Learning And Insights On Impacted Personnel."
      },
      {
        name: 'Live Monitoring',
        imgPath: '../../../assets/Proximity & Contact Tracing/LIVE MONITORING.png',
        description: "Remotely Monitor Safety Of Personnel From Anywhere."
      },
    ]
  }

}
