import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

   public blogs: any = [];
   public news: any = [];
   public flagNew : Boolean = true;
   public month = ["","Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
   public environment = environment;

   constructor(private http: HttpClient) {
    this.loadMore();
 }

 ngOnInit() {
 }
 loadMore() {
  this.http.get(`${environment.baseUrl}/blogs/load/${this.blogs.length}/6`).subscribe(res => {
        this.blogs = this.blogs.concat(res['data']);
    if(this.blogs.length==parseInt(res['info'])) {
           this.flagNew=false;
         }
        });

 }

}
