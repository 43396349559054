import { Component, OnInit } from '@angular/core';
import { Feature } from '../components/componentDS';

@Component({
  selector: 'app-ufreeze',
  templateUrl: './ufreeze.component.html',
  styleUrls: ['./ufreeze.component.css'],
})
export class UfreezeComponent implements OnInit {
  public features: Array<Feature>;
  public cards:any;
  public lorawanImages:any;
  constructor() { }

  ngOnInit() {
    this.features=[
      { 
        name:'Remote Monitoring',
        imgPath:'../../../assets/uFreeze/REMOTE MONITORING.png',
        description:"Deployed Sensors Detect temperature and humidity and send alerts wirelessly to the cloud.",
        id:'1'},
      { 
        name:'Analytics and Reporting',
        imgPath:'../../../assets/uFreeze/ANALYTICS & REPORTING.png',
        description:"View Temperature And notifications logs, plus run and schedule reports.",
        id:'2'
      },
      { 
        name:'Sensor Maps', 
        imgPath:'../../../assets/uFreeze/SENSOR MAPS.png',
        description:"Upload Facility maps and visualize sensor location and readings.",
        id:'3'
      },
      { 
        name:'Quick and Easy Setup' ,
        imgPath:'../../../assets/uFreeze/QUICK & EASY SETUP.png',
        description:"Easily Install sensors and gateway and be up and running in minutes.",
        id:'4'
      },
      { 
        name:'Historical Insights', 
        imgPath:'../../../assets/uFreeze/HISTORICAL INSIGHTS.png',
        description:"access to historical data provides deeper learning and insights on your commercial activity.",
        id:'5'},
      { 
        name: 'Live Monitoring', 
        imgPath:'../../../assets/uFreeze/LIVE MONITORING.png',
        description:"mobile app to access temperature and humidity status from anywhere.",
        id:'6'
      },
    ]

    this.cards= [
      { 
      name: 'Cold Storage Insights',
      src: '../../../assets/uFreeze/WASTE COLLECTION INSIGHTS.png',
      desp: "Cold Storage Units Can Be Seen On The Map And Change Colors Based On The Temperature Readings. Details On The Cold Storage Units Can Be Seen By Simply Clicking On The Temperature Icon."
      },

      { 
      name:'Real Time Alerts',
      src:'../../../assets/uFreeze/REAL-TIME ALERTS.png',
      desp:"Your Phone Will Receive Notifications In Real-Time Once Temperatures Have Exceeded Desired Thresholds. Configure Notifications To Your Liking And Remain Informed Of All Your Cold Storage Units."
      },

      { 
      name:'Temperature & Humidity Monitoring',
      src:'../../../assets/uFreeze/ROUTE OPTIMIZATION.png',
      desp:"Remotely Monitor The Temperature & Humidity Levels Of Your Cold Storage Units To Maintain the Quality Of Your Goods."
      },

      { 
      name:'Android & iOS Support' ,
      src:'../../../assets/uFreeze/ANDROID & iOS Support.png',
      desp:"Our Mobile Application Supports Android Operating System. iOS Support Will Be Coming Soon."
      },

      { 
      name:'User Friendly',
      src:'../../../assets/uFreeze/USER FRIENDLY DESIGN.png',
      desp:"Superior Customer Experience Is Important To Us. Our Mobile App Is Designed For Ease Of Use And Simplistic Functionality."
      }
    ]

    this.lorawanImages = [
      {
        src:"../../../assets/uFreeze/Device.png",
        para:"DEVICE",
        id:1
      },

      {
        src:"../../../assets/uFreeze/Iot Platform.png",
        para:"IoT PLATFORM",
        id:2
      },

      {
        src:"../../../assets/uFreeze/uFreeze App.png",
        para:"MOBILE APP",
        id:3
      }
    ]
  }
}