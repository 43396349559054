import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connecting-device2',
  templateUrl: './connecting-device2.component.html',
  styleUrls: ['./connecting-device2.component.css']
})
export class ConnectingDevice2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
