import { ImplicitReceiver } from "@angular/compiler";
import { Component,OnInit } from "@angular/core";
@Component ( {
    selector: 'app-managed-network-service-page',
    templateUrl: './managed-network-service-page.component.html',
    styleUrls: ['./managed-network-service-page.component.css']
})
export class ManagedNetworkServicePageComponent implements OnInit {

    constructor ( ) { }

    ngOnInit () {
        
    }
}