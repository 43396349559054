import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.css']
})
export class CaseStudiesComponent implements OnInit {

  public caseStudies = [];

  constructor(private http: HttpClient) {
    this.getAllData();
  }

  ngOnInit() {

  }
  getAllData() {
    this.http.get(`${environment.baseUrl}/caseStudies/getAll`).subscribe(res => {
      this.caseStudies = res['data']
    });
  }

}