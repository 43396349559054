import { Component, OnInit, OnDestroy } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})



export class AboutusComponent implements OnInit, OnDestroy {

  public employeeDetails: any = [];
  public environment = environment;
  
  constructor(private http: HttpClient) {
     this.http.get(`${environment.baseUrl}/employees/getall`).subscribe(res => this.employeeDetails = res['data']);
       }
  ngOnInit() {
  }
  
  
  ngOnDestroy() {
  }

}


  