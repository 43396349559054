import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})

export class ContactusComponent implements OnInit {

  
  public contactUs: FormGroup;
  public buttonText = "Submit";
  public environment = environment;

  constructor(private snackBar: MatSnackBar) {
    this.contactUs = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.email
      ]),

      name: new FormControl("", [
        Validators.required,
        Validators.pattern ("[a-zA-Z][a-zA-Z ]+"),
        Validators.minLength(2)
      ]),
      
      code: new FormControl("",[
        Validators.required,
        Validators.pattern ('[\+]?[0-9]{2,3}')
      ]),

      phone: new FormControl("", [
        Validators.required,
        Validators.pattern ('((\\+91-?)|0)?[0-9]{10}')
      ]),

      message: new FormControl("", [
        Validators.required,
        Validators.minLength(2)
      ])
    });
  }

  ngOnInit() {
  }
  
  onSubmit( formDirective: FormGroupDirective ) {
    if(this.contactUs.valid){
    fetch(`${environment.baseUrl}/contactus/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    
      body: JSON.stringify ({
        name: this.contactUs.value.name,
        message: this.contactUs.value.message,
        email: this.contactUs.value.email,
        phone:this.contactUs.value.code*10000000000 + this.contactUs.value.phone
      })
    })    
      .then(res => {
        formDirective.resetForm();
        this.contactUs.reset();
        this.buttonText = "Submitted";
        this.snackBar.open("Form successfully submitted!", 'Dismiss', {duration: 5000 });
        
      })
      .catch(error => console.log(error));

    fetch(`${environment.baseUrl}/ses_sendemail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },

      body: JSON.stringify ({
        name: this.contactUs.value.name,
        message: this.contactUs.value.message,
        email: this.contactUs.value.email,
        phone: this.contactUs.value.phone
      })
    })
      .then(res => {
        return res.json()
      })
      .then(data => console.log(data))
      .catch(error => console.log('err'))
    }
  }
}