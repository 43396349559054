import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  public uploadScreen: Boolean = true;
  public uploadFileFormGroup: FormGroup;
  public targetedFile;
  public uploadedFileUrl: string = '';
  public showCopiedUrlText: Boolean = false;

  constructor(private http: HttpClient) {
    this.uploadFileFormGroup = new FormGroup({
      file: new FormControl("", Validators.required),
      secretKey: new FormControl("", Validators.required),
      bucketName: new FormControl("", Validators.required),
      url: new FormControl("")
    })
  }

  ngOnInit() {
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      this.targetedFile = event.target.files[0];
    }
  }

  copyUrl() {
    navigator.clipboard.writeText(this.uploadFileFormGroup.get('url').value);
    this.showCopiedUrlText = true;
    setTimeout(() => {
      this.showCopiedUrlText = false;
      }, 2500);
  }

  onSubmit(formGroup: any) {
    if (this.uploadFileFormGroup.valid) {
      const formData = new FormData();
      formData.append('file', this.targetedFile);
      formData.append('secretKey', this.uploadFileFormGroup.get('secretKey').value);
      formData.append('bucketName', this.uploadFileFormGroup.get('bucketName').value);
      this.http.post(`${environment.baseUrl}/uploadFile`, formData).toPromise().then(res => {
        if (res['url']) {
          this.uploadedFileUrl = res['url'];
          this.uploadFileFormGroup.get('url').setValue(this.uploadedFileUrl);
          this.uploadScreen = false;
        }
      })
    }
  }

  uploadAnother() {
    this.uploadFileFormGroup.get('file').setValue(null);
    this.uploadFileFormGroup.get('secretKey').setValue(null);
    this.uploadFileFormGroup.get('bucketName').setValue('');
    this.uploadFileFormGroup.get('url').setValue(null);
    this.uploadScreen = true;
    this.showCopiedUrlText = false;
  }

}