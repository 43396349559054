import { Component,Input, OnInit } from '@angular/core';
import { solutionDesc } from '../componentDS';

@Component({
  selector: 'app-end-to-end',
  templateUrl: './end-to-end.component.html',
  styleUrls: ['./end-to-end.component.css']
})
export class EndToEndComponent implements OnInit {

  @Input() lorawanImages: Array<solutionDesc> = [];

  constructor() { }

  ngOnInit() {
  }

}
