import { ImplicitReceiver } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";

@Component ({
    selector: 'app-netsy',
    templateUrl: './netsy.component.html',
    styleUrls: ['./netsy.component.css']
})

export class NetsyComponent implements OnInit {
  public Cards: any;
  public features: any;
  
  constructor ( ) { }
  
  ngOnInit () {
    this.Cards = [
      {
        name: 'Optimal Deployment Capex',
        src: '../../../assets/Netsy/Optimal Deployment Capex.png',
        desp: 'Conduct Advanced Scientific Simulations To Optimally Choose The Right Tower Locations And Optimal Gateway Count To Save Cost At Capex And Still Meet The Customer Requirements.'
      },
      
      {
        name: 'Streamlined Site-Survey Operations',
        src: '../../../assets/Netsy/Streamlined Site-Survey operations.png',
        desp: 'Effectively Conduct Surveys Post Deployment To Evince The Network Presence To The Client And Ensure Smooth Project Handover With Quality Reports.'
      },
      
      {
        name: 'Advanced Network Monitoring',
        src: '../../../assets/Netsy/Advanced Network Monitoring.png',
        desp: 'Integrated Maps With Performance Visualization Features To Include Suggestive-Indicators For Predictive Maintenance Of The Network To Establish Sla-Backed Network For The Clients.'
      },
    ]
    
    this.features = [
      {
        name: 'NETWORK SURVEYS',
        imgPath: '../../../assets/Netsy/Network Surveys.png',
        description: "Conduct surveys with no additional software/hardware on your devices. Easily visualize and generate network heatmaps with just a few clicks.",
        id: '1'
      },
      
      {
        name: 'PERFORMANCE COMPARISON',
        imgPath: '../../../assets/Netsy/Performance Comparison.png',
        description: "Easily compare equipment performance in the field and use system-generated detailed reports to share with your customers.",
        id: '2'
      },
      
      {
        name: 'CREATE BOUNDARIES',
        imgPath: '../../../assets/Netsy/Create Boundaries.png',
        description: "Upload KMLs, TIFFs & 10 other GIS formats to view the boundary of interest fencing the area of network requirement. Also, draw and store your own boundaries.",
        id: '3'
      },
      
      {
        name: 'FILTER BEST TOWERS',
        imgPath: '../../../assets/Netsy/Filter Best Towers.png',
        description: "Apply filters on the tower locations based on their tower vendors, heights, coverage radius and much more.",
        id:'4'
      },
      
      {
        name:'DEVICE PERFORMANCE SIMULATION',
        imgPath:'../../../assets/Netsy/Device Performance Simulation.png',
        description: "Simulate signal strengths on device locations based on different pathloss models.",
        id: '5'
      },
      
      {
        name: 'COVERAGE VIEWSHEDS',
        imgPath: '../../../assets/Netsy/Coverage Viewsheds.png',
        description: "View elevation viewshed of every tower location based on its height, radius, gateway model & device attributes. Add custom towers for coverage enhancements.",
        id: '6'
      },
      
      {
        name: 'MONITOR NETWORK PERFORMANCE',
        imgPath: '../../../assets/Netsy/Monitor Network Performance.png',
        description: "Analyse KPIs like RSSI, SNR, PSR and much more to ensure SLA delivery. Also get SLA-alarming alerts & system enhancement suggestion to maintain the performance.",
        id: '7'
      },
      
      {
        name: 'PREDICTIVE MAINTENANCE',
        imgPath: '../../../assets/Netsy/Predictive Maintenance.png',
        description: "Along with granular insights into your projects, get SLA-alarming alerts & suggestions on updating device profiles, base-station profiles to maintain the performance.",
        id: '8'
      },
    ]
  }
}