import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-career-form',
    templateUrl: './career-form.component.html',
    styleUrls: ['./career-form.component.css']
})

export class CareerFormComponent implements OnInit {
    panelOpenState: false;
    public CareerForm: FormGroup;
    public resumeLink: string = '';
    public resume: File = null;
    public invalidFileFormat: Boolean = false;
    public visibility: Boolean;
    @Input() careerID: string;
    public environment = environment;
    constructor(private form: FormBuilder, private http: HttpClient, private snackBar: MatSnackBar) {
        this.CareerForm = new FormGroup({
            email: new FormControl("", [
                Validators.required,
                Validators.email
            ]),
            name: new FormControl("", [
                Validators.required,
                Validators.pattern ("[a-zA-Z][a-zA-Z ]+"),
                Validators.minLength(2)
            ]),
            phone: new FormControl("", [
                Validators.required,
                Validators.pattern('((\\+91-?)|0)?[0-9]{10}')
            ]),
            description: new FormControl("", [
                Validators.required,
                Validators.minLength(2)
            ]),
            location: new FormControl("", [
                Validators.required,
                Validators.pattern ("[a-zA-Z][a-zA-Z ]+")
            ]),
            lnkd: new FormControl("", [
                Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
            ]),
            resume: new FormControl("", [
                Validators.required
            ]),
            resumeLink: new FormControl("")
        });
    }

    ngOnInit() {
        this.visibility = true;
    }

    uploadResume(event) {
        this.invalidFileFormat = false;
        let file = event.target.files[0];
        console.log(file);
        
        let fileType: string = '';
        if (file && file.type) {
            fileType = file.type.split('/')[1].split('+')[0];
        }
        if (fileType !== 'pdf') {
            if (fileType) {
                this.invalidFileFormat = true;
            }
            this.CareerForm.get('resume').setValue(null);
        } else {
            if (event.target.files.length > 0) {
                this.resume = event.target.files[0];
                const formData = new FormData();
                formData.append('file', event.target.files[0]);
                this.http.post(`${environment.baseUrl}/file`, formData).toPromise().then(res => {
                    this.resumeLink = Object.values(res)[0];
                })
            }
            this.visibility = false;
        }
    }

    onDelete() {
        this.visibility = true;
        this.CareerForm.controls['resume'].reset();
        const formData = new FormData();
        formData.append('file', this.resume);
        let name = this.resume.name;
        this.http.delete(`${environment.baseUrl}/file/${name}`).subscribe(data => {
            
        });
    }


    onSubmit(formData: any, formDirective: FormGroupDirective) {
        if(this.CareerForm.valid) {
            console.log(this.CareerForm.value);
            this.CareerForm.get('resumeLink').setValue(this.resumeLink);
            this.CareerForm.value.careerID = this.careerID;
            console.log(this.CareerForm.value + " New");
            this.http.post(`${environment.baseUrl}/careerforms/add`, this.CareerForm.value).subscribe(
                (data) => {
                    console.log(data.toString() + " Stored Successfully");
                    this.snackBar.open("Application successfully submitted!", 'Dismiss', {duration: 5000 });
                },
                (err) => {
                    console.log(err);
                }
            );
            formDirective.resetForm();
            this.CareerForm.reset();
            this.visibility = true;
        }
    }
}