import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uBeam',
  templateUrl: './uBeam.component.html',
  styleUrls: ['./uBeam.component.css']
})
export class UBeamComponent implements OnInit {
  public features: any;
  public cards: any;
  public lorawanImages: any;
  constructor() { }

  ngOnInit() {
    this.features = [
      {
        name: 'Remote Control',
        imgPath: '../../../assets/uBeam/REMOTE-CONTROL.png',
        description: "Remotely Turn On/Off or dim your lights from any where in the world",
        id: '1'
      },
      {
        name: 'Real Time Data Analytics',
        imgPath: '../../../assets/uBeam/REAL-TIME-DATA-ANALYTICS.png',
        description: "Data processing,analytics, and visualization of smart lighting activity is available in real time.",
        id: '2'
      },
      {
        name: 'Energy Monitoring',
        imgPath: '../../../assets/uBeam/ENERGY-MONITORING.png',
        description: "Get automatic updates on the energy consumption of each light.",
        id: '3'
      },
      {
        name: 'Centralized Command and Control',
        imgPath: '../../../assets/uBeam/CENTRALIZED-COMMAND-&-CONTROL.png',
        description: "Manage all your smart lighting projects in one location.",
        id: '4'
      },
      {
        name: 'Historical Insights',
        imgPath: '../../../assets/uBeam/HISTORICAL-INSIGHTS.png',
        description: "access to historical smart lighting data provides deeper learning and insights on outages",
        id: '5'
      },
      {
        name: 'Automated Alerts',
        imgPath: '../../../assets/uBeam/AUTOMATED-ALERTS.png',
        description: "receive notifications in real-time based on user defined configurations",
        id: '6'
      },
    ]

    this.cards = [
      {
        name: 'Smart Lighting Insights',
        src: '../../../assets/uBeam/SMART-LIGHTING-INSIGHTS.png',
        desp: "Smart Lights Can Be Seen On The Map And Change Colors Based On The Power Availability. Details On The Smart Light Can Be Seen By Simply Clicking On The Light Icon."
      },

      {
        name: 'Real-Time Alerts',
        src: '../../../assets/uBeam/REAL-TIME-ALERTS.png',
        desp: "Your Phone Will Receive Notifications In Real-Time Once Smart Lights Have An Issue. Configure Notifications To Your Liking And Remain Informed Of Your Smart Lighting Deployments."
      },

      {
        name: 'Individual Light Control',
        src: '../../../assets/uBeam/INDIVIDUAL-LIGHT-CONTROL.png',
        desp: "Remotely Send On/Off Commands To Individual Light Controllers With A Click Of A Button."
      },

      {
        name: 'Android & iOS Support',
        src: '../../../assets/uBeam/ANDROID-&-iOS-Support.png',
        desp: "Our Mobile Application Supports Android Operating Systems. iOS Support Will Be Coming Soon."
      },

      {
        name: 'User Friendly Design',
        src: '../../../assets/uBeam/USER-FRIENDLY-DESIGN.png',
        desp: "Our Customer's Experience Is Important To us. Our Mobile App Was Designed For Ease Of use And Simplistic Functionality."
      }
    ]

    this.lorawanImages = [
      {
        src: "../../../assets/uBeam/uBeam Device.png",
        para: "DEVICE"
      },
      {
        src: "../../../assets/uBeam/IoT Platform uBeam.png",
        para: "IoT PLATFORM"
      },
      {
        src: "../../../assets/uBeam/uBeam App.png",
        para: "MOBILE APP"
      }
    ]

  }
}